import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios'; // Import Axios
const image4 = 'https://booksarereborn-images.s3.us-east-1.amazonaws.com/image4.png';
import { ClipLoader } from 'react-spinners';
import Alert from './Alert'; // adjust the path based on your project structure

const ContactUs: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // New state to handle loading spinner
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  type AlertType = "success" | "error" | "warning" | "info";

  interface AlertState {
    show: boolean;
    message: string;
    type: AlertType;
  }
  
  const [alert, setAlert] = useState<AlertState>({
    show: false,
    message: '',
    type: 'success', // This is now correctly inferred as AlertType
  });
  
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleSignUpClick = () => {
    navigate('/auth', { state: { isLogin: false } });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
  
    const contactData = { name, email, order_number: orderNumber, message };
  
    try {
      const response = await axios.post('https://baresellerapp-backend-dra9gpc5ghhuggb0.eastus2-01.azurewebsites.net/send_contact_message', contactData);
  
      if (response.status === 200) {
        setAlert({
          show: true,
          message: 'Your message was sent successfully!',
          type: 'success',
        });
        // Reset form fields
        setName('');
        setEmail('');
        setOrderNumber('');
        setMessage('');
      } else {
        setAlert({
          show: true,
          message: response.data.error || 'Failed to send your message.',
          type: 'error',
        });
      }
    } catch (error) {
      console.error('Error while sending contact message:', error);
      setAlert({
        show: true,
        message: 'An error occurred while sending your message.',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-[#c3e7f5] via-[#0e4668] to-[#121212]">
      <header className="w-full bg-[#b3dff5] shadow-md p-4 fixed top-0 left-0 flex justify-between items-center z-50 border-b border-gray-200">
      <div className="flex items-center justify-center flex-1">
        <Link to="/" className="text-3xl font-bold text-gray-900 flex items-center space-x-3 hover:text-blue-700 transition duration-300" aria-label="BooksAreReborn Home">
          <img alt="BooksAreReborn Logo" src={image4} className="h-12 w-auto animate-bounce" />
          <span className="tracking-wider">BooksAreReborn</span>
        </Link>
          <nav className="hidden lg:flex space-x-4 mx-auto">
            <Link to="/" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Home
            </Link>
            <Link to="/your-account" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Your Account
            </Link>
            <Link to="/get-offer" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Cart
            </Link>
          </nav>
        </div>
        <div className="flex items-center space-x-4">
          {currentUser ? (
            <button
              onClick={handleLogout}
              className="hidden lg:block bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full shadow transition"
            >
              Log out
            </button>
          ) : (
            <>
              <button
                onClick={() => navigate('/auth')}
                className="hidden lg:block bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-full shadow transition"
              >
                Sign In
              </button>
              <button
                onClick={handleSignUpClick}
                className="hidden lg:block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full shadow transition"
              >
                Sign Up
              </button>
            </>
          )}
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="lg:hidden p-2 rounded-full bg-gray-900 text-white"
          >
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
      </header>

      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#57b0dc] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 shadow-xl rounded-l-2xl">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5 flex items-center space-x-2">
              <img
                alt="BooksAreReborn Logo"
                src={image4}
                className="h-12 w-auto"
              />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link to="/" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Home
                </Link>
                <Link to="/your-account" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Your Account
                </Link>
                <Link to="/get-offer" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Cart
                </Link>
              </div>
              <div className="py-6">
                {currentUser ? (
                  <button
                    onClick={handleLogout}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log out
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => navigate('/auth')}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign In
                    </button>
                    <button
                      onClick={handleSignUpClick}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign Up
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>

<main className="pt-28 w-full max-w-2xl px-6 mx-auto">
  <section className="mb-12 bg-white p-8 rounded-lg shadow-xl">
    <h1 className="text-center text-5xl font-extrabold text-gray-900 mb-8">Contact Us</h1>

    <p className="mb-4 text-gray-700 leading-relaxed">
      Due to high demand, order processing times might extend by a few days. We appreciate your patience as we ensure your items are handled with care.
    </p>
    <p className="mb-4 text-gray-700 leading-relaxed">
      We've compiled answers to common inquiries on our 
      <a href="/faq" className="text-green-600 hover:text-green-800 underline"> FAQ page</a>. If your question isn't covered there, feel free to reach out to us directly.
    </p>
    <p className="mb-4 text-gray-700 leading-relaxed">
      Please note that our response times might be slightly delayed during peak seasons. To ensure prompt service, avoid submitting multiple requests regarding the same issue.
    </p>
    <p className="mb-8 text-gray-700 leading-relaxed">
      To make sure you receive our responses, add our email to your contact list. If you don't see a reply in your inbox, please check your spam or junk folder.
    </p>
    {!loading ? (

    <form onSubmit={handleSubmit} className="space-y-8">
      <div>
        <label htmlFor="name" className="block text-lg font-semibold text-gray-800">
          Full Name
        </label>
        <input
          type="text"
          id="name"
          className="mt-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm p-3"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="email" className="block text-lg font-semibold text-gray-800">
          Email Address
        </label>
        <input
          type="email"
          id="email"
          className="mt-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm p-3"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="orderNumber" className="block text-lg font-semibold text-gray-800">
          Order Number (Optional)
        </label>
        <input
          type="text"
          id="orderNumber"
          className="mt-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm p-3"
          value={orderNumber}
          onChange={(e) => setOrderNumber(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="message" className="block text-lg font-semibold text-gray-800">
          Your Message
        </label>
        <textarea
          id="message"
          rows={5}
          className="mt-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm p-3"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
      </div>
      <Alert
        title={alert.type === 'success' ? 'Success' : 'Error'}
        message={alert.message}
        type={alert.type}
        show={alert.show}
        onClose={() => setAlert({ ...alert, show: false })}
        autoDismiss={true}
        duration={5000} // duration in milliseconds
      />
      <div>
      <button
          type="submit"
          className="w-full bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white px-5 py-4 rounded-lg shadow-xl font-bold text-lg transition duration-300 flex flex-col items-center space-y-2"
        >
          {loading ? (
            <div className="flex flex-col items-center space-y-2">
              <h2 className="text-lg font-semibold">Your message is being sent</h2>
              <p className="text-gray-200">Please wait, this will only take a moment...</p>
              <ClipLoader color="#fff" size={24} /> {/* Spinner */}
            </div>
          ) : (
            'Send Message'
          )}
        </button>
      </div>
            </form>
          ) : (
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h2 className="text-lg font-semibold mb-4">Your message is being sent</h2>
              <p className="text-gray-600 mb-4">Please wait, we are processing your message...</p>
              <ClipLoader color="#3498db" size={40} /> {/* Spinner */}
            </div>
          )}
  </section>
</main>
<footer className="bg-gray-900 text-white py-10">
  <div className="container mx-auto px-6">
    <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
      <div className="flex items-center space-x-4 flex-shrink-0">
        <img
          alt="BooksAreReborn Logo"
          src={image4}
          className="h-14 w-auto"
        />
        <span className="text-xl font-bold tracking-wide">BooksAreReborn</span>
      </div>
      <div className="flex flex-wrap justify-center space-x-4 md:space-x-8 text-center md:text-left">
        <Link
          to="/privacy-policy"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Privacy Policy
        </Link>
        <Link
          to="/terms-of-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Terms of Use
        </Link>
        <Link
          to="/acceptable-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Acceptable Use
        </Link>
        <Link
          to="/contact-us"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Contact Us
        </Link>
        <Link
          to="/buyback-guide"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Buyback Guide
        </Link>
        <Link
          to="/faq"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          FAQs
        </Link>
        <a
          href="https://www.affiliatly.com/af-1070204/affiliate.panel?mode=register"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Affiliate Program
        </a>
      </div>
    </div>
    <div className="border-t border-gray-700 mt-8 pt-4">
      <div className="text-center text-gray-500 text-xs">
        &copy; {new Date().getFullYear()} BooksAreReborn. All rights reserved.
      </div>
    </div>
  </div>
</footer>

    </div>
  );
};

export default ContactUs;
