import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db, rtdb } from '../firebase';
import { ref, update } from 'firebase/database';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
const image4 = 'https://booksarereborn-images.s3.us-east-1.amazonaws.com/image4.png';
import debounce from 'lodash/debounce'; // Import lodash debounce or implement your own debounce function
// import { ClipLoader } from 'react-spinners'; // Import the spinner loader for feedback
import { motion } from 'framer-motion';
import ga from '../ga-init'; // Import GA tracking module
import ConfirmationModal from './ConfirmationModal';
import { FaSearch, FaCalculator, FaCheckCircle } from 'react-icons/fa';
// import { useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase'; // Import your Firebase auth instance


interface CartItem {
  totalEarnings: number;
  itemName: string;
  isbn: string;
  quantity: number;
  imageLink: string;
  initialEarnings: number;
  dimensions: {
    length: number;
    width: number;
    height: number;
  };
  weight: number;
}

const Getoffer: React.FC = () => {
  const [isbn, setIsbn] = useState('');
  const [quantity, setQuantity] = useState<number | string>(1);
  const [shoppingCart, setShoppingCart] = useState<CartItem[]>([]);
  const [totalValues, setTotalValues] = useState(0);
  const [totalQuantities, setTotalQuantities] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  const [totalHeight, setTotalHeight] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [formData, setFormData] = useState<any>({});
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');  // Error message to provide feedback
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalAction, setModalAction] = useState<(() => void) | null>(null);
  const [itemToUpdate, setItemToUpdate] = useState<CartItem | null>(null); // Item needing user input
  const [isPromptOpen, setIsPromptOpen] = useState(false); // Controls prompt display

  const [loading, setLoading] = useState(false);  // For spinner feedback
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const isbn = searchParams.get("isbn");
    const fromSource = searchParams.get("from_source");
    const aff = searchParams.get("aff");
  
    // Function to delete cookies
    const clearAffiliateCookies = () => {
      document.cookie = "affiliatly_v3=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Lax;";
    };
  
    // Function to check and handle affiliate tracking
    const handleAffiliateTracking = () => {
      if (sessionStorage.getItem("affiliateChecked")) return; // Prevent duplicate execution
      sessionStorage.setItem("affiliateChecked", "true");
  
      setTimeout(() => {
        const existingCookie = document.cookie
          .split("; ")
          .find((row) => row.startsWith("affiliatly_v3="));
  
        if (existingCookie) {
          const existingAffiliateId = existingCookie.split("&aff_uid=")[1]?.split("&")[0];
          
          // If the aff_uid does not match the URL aff, clear cookies and reload page
          if (existingAffiliateId !== aff) {
            console.log(`Affiliate ID mismatch. Clearing cookies and reloading for aff=${aff}`);
            clearAffiliateCookies();
            window.location.reload();
          }
        } else {
          console.log(`No affiliate cookie found, ensuring tracking for aff=${aff}`);
          window.location.reload();
        }
      }, 500); // Delay to allow cookie to be set properly
    };
  
    // Automatically add aff=5 if missing and reload the page once
    if (fromSource === "dealoz" && !aff && !sessionStorage.getItem("affUrlUpdated")) {
      searchParams.set("aff", "5");
      sessionStorage.setItem("affUrlUpdated", "true");
  
      // Replace URL with updated query params and reload the page
      window.location.replace(`${window.location.pathname}?${searchParams.toString()}`);
      return; // Exit effect early to avoid duplicate execution
    }
  
    if (aff) {
      handleAffiliateTracking();
    }
  
    // Ensure the page reloads only once to set cookies properly for dealoz source
    if (fromSource === "dealoz" && aff === "5") {
      if (!sessionStorage.getItem("affReloaded")) {
        sessionStorage.setItem("affReloaded", "true");
        setTimeout(() => {
          window.location.reload();
        }, 500); // Adding delay to prevent race conditions
        return;
      }
    }
  
    const fetchItemFromBackend = async (isbn: string, user: any) => {
      try {
        console.log(`Fetching item details from backend for ISBN: ${isbn}`);
  
        const placeholderItem: CartItem = {
          isbn,
          quantity: 1,
          totalEarnings: 0,
          initialEarnings: 0,
          itemName: "Fetching details...",
          imageLink: "",
          dimensions: { length: 0, width: 0, height: 0 },
          weight: 0,
        };
  
        setShoppingCart((prevCart) => mergeCartItems(prevCart, [placeholderItem]));
  
        // Call the backend API
        const response = await axios.get(
          `https://baresellerapp-backend-dra9gpc5ghhuggb0.eastus2-01.azurewebsites.net/fetch-item`,
          {
            params: { isbn },
            withCredentials: true,
          }
        );
  
        const itemDetails = response.data;
  
        if (itemDetails) {
          const newCartItem: CartItem = {
            isbn,
            quantity: 1,
            totalEarnings: parseFloat(itemDetails.totalEarnings) || 0,
            initialEarnings: parseFloat(itemDetails.totalEarnings) || 0,
            itemName: itemDetails.itemName || "",
            imageLink: itemDetails.imageLink || "",
            dimensions: itemDetails.dimensions || { length: 0, width: 0, height: 0 },
            weight: parseFloat(itemDetails.weight) || 0,
          };
  
          setShoppingCart((prevCart) => {
            const updatedCart = mergeCartItems(prevCart, [newCartItem]);
  
            // Save to localStorage based on user authentication
            if (user) {
              saveCartToFirestoreAndRTDB(updatedCart); // Use Firestore/RTDB for authenticated users
            } else {
              localStorage.setItem("shoppingCart", JSON.stringify(updatedCart));
            }
  
            return updatedCart;
          });
        } else {
          throw new Error(`Item with ISBN ${isbn} not found in DynamoDB.`);
        }
      } catch (error) {
        console.error("Error fetching item from backend:", error);
  
        // Remove placeholder on error
        setShoppingCart((prevCart) => prevCart.filter((item) => item.isbn !== isbn));
        setErrorMessage("Failed to fetch item details. Please try again later.");
      }
    };
  
    const handleAffiliateRequest = (user: any) => {
      if (isbn) {
        fetchItemFromBackend(isbn, user);
      }
    };
  
    // Listen for user authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      handleAffiliateRequest(user); // Pass the user to ensure correct logic
    });
  
    return () => unsubscribe(); // Cleanup the listener
  }, [window.location.search]);
      // Track adding items to the cart
  const trackAddToCart = ga.trackEventBuilder('Cart');
  const trackShipmentCreation = ga.trackEventBuilder('Shipment');

  // Check for cookie consent
const isAnalyticsEnabled = () => {
  return localStorage.getItem('cookieConsent') === 'accepted';
};

  useEffect(() => {
    const fetchCart = async () => {
      let mergedCart: CartItem[] = [];

      if (currentUser) {
        const cartRef = doc(db, 'carts', currentUser.uid);
        const cartDoc = await getDoc(cartRef);

        if (cartDoc.exists()) {
          const cartData = cartDoc.data() as { shopping_cart: CartItem[], totalValues: number, totalQuantities: number };
          mergedCart = cartData.shopping_cart || [];
          setTotalValues(cartData.totalValues || 0);
          setTotalQuantities(cartData.totalQuantities || 0);
        }
      }

      const localCartData = localStorage.getItem('shoppingCart');
      if (localCartData) {
        const localCart = JSON.parse(localCartData) as CartItem[];
        mergedCart = mergeCartItems(mergedCart, localCart);
      }

    // Validate the total earnings after merging the cart
    // const totalEarnings = mergedCart.reduce((acc, item) => acc + item.totalEarnings, 0);

    // if (totalEarnings > 100) {
    //   setErrorMessage('Your total cart earnings exceed the $100 limit. Please adjust your cart.');
    //   mergedCart = mergedCart.filter(item => item.totalEarnings <= 100); // Adjust cart as per your requirement
    // }

      setShoppingCart(mergedCart);
      calculateTotals(mergedCart);

      if (currentUser) {
        await saveCartToFirestoreAndRTDB(mergedCart);
        localStorage.removeItem('shoppingCart');
      } else {
        localStorage.setItem('shoppingCart', JSON.stringify(mergedCart));
      }
    };

    fetchCart();
  }, [currentUser]);

  const debouncedAddToCart = debounce(
    async (isbn: string, quantity: number) => {
      const formattedIsbn = formatIsbn(isbn);
  
      // Validate the ISBN before proceeding
      if (!isValidIsbn(formattedIsbn)) {
        console.log('Invalid ISBN entered. Waiting for a valid ISBN.');
        return; // Exit if ISBN is invalid or incomplete
      }
  
      try {
        // Call the actual function to add to the cart
        await handleAddToCart(formattedIsbn, quantity);
      } catch (error) {
        console.error('Error adding item to cart via debounced function:', error);
      }
    },
    300, // Debounce delay
    { leading: false, trailing: true } // Ensure only the trailing call executes
  );
  const saveCartToFirestoreAndRTDB = async (cart: CartItem[]) => {
    if (currentUser) {
      const cartRef = doc(db, 'carts', currentUser.uid);
      await setDoc(cartRef, {
        shopping_cart: cart,
        totalValues,
        totalQuantities
      });

      const cartRtdbRef = ref(rtdb, `carts/${currentUser.uid}`);
      await update(cartRtdbRef, {
        shopping_cart: cart,
        totalValues,
        totalQuantities
      });
    }
  };
  useEffect(() => {
    const pendingIsbn = localStorage.getItem('pendingIsbn');
    if (pendingIsbn) {
      handleAddToCart(pendingIsbn, 1);
      localStorage.removeItem('pendingIsbn');
    }
  }, []);
  const handleAddToCart = async (isbn: string, quantity: number, isAffiliateRequest = false) => {
    setLoading(true); // Start the loading spinner
  
    const formattedIsbn = formatIsbn(isbn);
    const affiliateId = localStorage.getItem('affiliateId'); // Retrieve affiliate ID from localStorage
    const isNoValueISBN = formattedIsbn.startsWith("979");
  
    try {
      // Check for duplicates in the current shopping cart
      const existingItemIndex = shoppingCart.findIndex(
        (item) => formatIsbn(item.isbn) === formattedIsbn
      );
  
      if (existingItemIndex !== -1) {
        // Item already exists; no need to add it again
        console.log(`Item with ISBN ${formattedIsbn} is already in the cart.`);
        setLoading(false); // Stop the spinner
        return;
      }
  
      // Add a placeholder item for optimistic UI
      const placeholderItem: CartItem = {
        isbn: formattedIsbn,
        quantity,
        totalEarnings: 0, // Placeholder value, backend will update
        initialEarnings: 0, // Placeholder
        itemName: 'Fetching details...', // Placeholder name
        imageLink: '', // Placeholder image
        dimensions: { length: 0, width: 0, height: 0 }, // Placeholder dimensions
        weight: 0, // Placeholder weight
      };
  
      setShoppingCart((prevCart) => [...prevCart, placeholderItem]); // Optimistically add item
  
      // Track the event with analytics (if enabled)
      if (isAnalyticsEnabled() && !isAffiliateRequest) {
        trackAddToCart({
          action: 'Add to Cart',
          label: formattedIsbn,
          value: quantity,
        });
      }
  
      // Send the request to the backend
      const response = await axios.post(
        'https://baresellerapp-backend-dra9gpc5ghhuggb0.eastus2-01.azurewebsites.net/add_to_cart',
        { isbn: formattedIsbn, quantity, affiliateId },
        { withCredentials: true }
      );
  
      const { shopping_cart: newCartItems } = response.data;
  
      if (!newCartItems || newCartItems.length === 0) {
        throw new Error('Received empty shopping cart from backend.');
      }
  
      // Merge the new items with the current shopping cart
      const updatedCart = mergeCartItems(shoppingCart, newCartItems.map((item: CartItem) => ({
        ...item,
        totalEarnings: isNoValueISBN ? 0 : calculateEarnings(item.initialEarnings, item.quantity),
      })));
  
      setShoppingCart(updatedCart); // Update the cart
      calculateTotals(updatedCart); // Recalculate totals
  
      // Save updated cart to Firestore or localStorage
      if (currentUser) {
        await saveCartToFirestoreAndRTDB(updatedCart);
      } else {
        localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
      }
  
      // Reset state for new input
      setIsbn(''); // Clear ISBN input
      setQuantity(1); // Reset quantity input
    } catch (error) {
      console.error('Error adding to cart:', error);
  
      // Remove the placeholder if the backend call fails
      setShoppingCart((prevCart) =>
        prevCart.filter((item) => item.isbn !== formattedIsbn)
      );
  
      setErrorMessage('Failed to add item to the cart. Please try again.');
    } finally {
      setLoading(false); // Stop the spinner
    }
  };
  
  
  const mergeCartItems = (existingCart: CartItem[], newCartItems: CartItem[]): CartItem[] => {
    const updatedCart = [...existingCart];
  
    newCartItems.forEach((newItem) => {
      const formattedIsbn = formatIsbn(newItem.isbn);
      const existingItemIndex = updatedCart.findIndex(
        (item) => formatIsbn(item.isbn) === formattedIsbn
      );
  
      if (existingItemIndex === -1) {
        // Add the new item if it doesn't already exist in the cart
        updatedCart.push(newItem);
      } else {
        // Update existing item with new details (e.g., total earnings)
        updatedCart[existingItemIndex] = {
          ...updatedCart[existingItemIndex],
          ...newItem, // Update fields
          quantity: Math.max(updatedCart[existingItemIndex].quantity, newItem.quantity), // Preserve highest quantity
        };
      }
    });
  
    return updatedCart;
  };
  
  useEffect(() => {
    const fetchSavedAddress = async () => {
      if (currentUser) {
        const addressRef = doc(db, 'addresses', currentUser.uid);
        const addressDoc = await getDoc(addressRef);
        if (addressDoc.exists()) {
          setFormData(addressDoc.data() as any);
        }
      }
    };
    fetchSavedAddress();
  }, [currentUser]);

  useEffect(() => {
    calculateTotals(shoppingCart);
  }, [shoppingCart]);

// Debounced function for handling adding the item to the cart (or performing API call)
// Debounced function for handling adding the item to the cart (or performing API call)
// Debounced function for adding the item to the cart


// Effect hook to trigger the debounced function when ISBN or quantity changes
useEffect(() => {
  if (isbn && isValidIsbn(isbn)) {
    debouncedAddToCart(isbn, Number(quantity));
  }

  return () => {
    // Cleanup the debounced function to avoid overlapping calls
    debouncedAddToCart.cancel();
  };
}, [isbn, quantity]);



  // Handle quantity change
  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (value >= 1) {
      setQuantity(value);
    }
  };

axios.defaults.withCredentials = true;


const checkForMissingDimensionsOrWeight = (cart: CartItem[]) => {
  const itemsWithMissingValues = cart.filter(item =>
    item.dimensions.length === 0 ||
    item.dimensions.width === 0 ||
    item.dimensions.height === 0 ||
    item.weight === 0
  );

  if (itemsWithMissingValues.length > 0) {
    setItemToUpdate(itemsWithMissingValues[0]); // Start with the first item needing input
    setIsPromptOpen(true);
    return false; // Indicate that missing values are found
  }

  return true; // All dimensions and weights are complete
};

  const MissingDimensionsPrompt: React.FC = () => {
    const handleDimensionChange = (dimension: 'length' | 'width' | 'height', value: number) => {
      setItemToUpdate((prev) =>
        prev ? { ...prev, dimensions: { ...prev.dimensions, [dimension]: value } } : null
      );
    };
  
    const handleWeightChange = (value: number) => {
      setItemToUpdate((prev) =>
        prev ? { ...prev, weight: value } : null
      );
    };
  
    return (
      <Dialog open={isPromptOpen} onClose={() => setIsPromptOpen(false)} className="fixed inset-0 z-50">
        <DialogPanel className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto mt-20 flex flex-col items-center space-y-4">
          {/* Product Image */}
          {itemToUpdate?.imageLink && (
            <img
              src={itemToUpdate.imageLink}
              alt={itemToUpdate.itemName}
              className="w-24 h-24 object-cover rounded-lg shadow"
            />
          )}
  
          {/* Product Info */}
          <h2 className="text-xl font-semibold mb-2 text-center">{itemToUpdate?.itemName}</h2>
          <p className="text-gray-700 text-sm mb-4">ISBN: {itemToUpdate?.isbn}</p>
  
          <p className="text-gray-600 mb-4 text-center">Please provide estimated dimensions and weight for this item:</p>
  
          {/* Dimension and Weight Inputs */}
          <input
            type="number"
            placeholder="Length (inches)"
            className="w-full mb-2 p-2 border rounded"
            value={itemToUpdate?.dimensions.length || ''}
            onChange={(e) => handleDimensionChange('length', parseFloat(e.target.value))}
            step="any"
            min="0"
            inputMode="decimal"
          />
          <input
            type="number"
            placeholder="Width (inches)"
            className="w-full mb-2 p-2 border rounded"
            value={itemToUpdate?.dimensions.width || ''}
            onChange={(e) => handleDimensionChange('width', parseFloat(e.target.value))}
            step="any"
            min="0"
            inputMode="decimal"
          />
          <input
            type="number"
            placeholder="Height (inches)"
            className="w-full mb-2 p-2 border rounded"
            value={itemToUpdate?.dimensions.height || ''}
            onChange={(e) => handleDimensionChange('height', parseFloat(e.target.value))}
            step="any"
            min="0"
            inputMode="decimal"
          />
          <input
            type="number"
            placeholder="Weight (pounds)"
            className="w-full mb-4 p-2 border rounded"
            value={itemToUpdate?.weight || ''}
            onChange={(e) => handleWeightChange(parseFloat(e.target.value))}
            step="any"
            min="0"
            inputMode="decimal"
          />
  
          {/* Save Button */}
          <button
            onClick={() => {
              updateCartWithUserInput();
              setIsPromptOpen(false);
            }}
            className="w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Save
          </button>
        </DialogPanel>
      </Dialog>
    );
  };
  
  
  
  // useEffect(() => {
  //   checkForMissingDimensionsOrWeight(shoppingCart); // Check only when shopping cart updates
  // }, [shoppingCart]);

  const updateCartWithUserInput = () => {
    if (itemToUpdate) {
      const updatedCart = shoppingCart.map(item =>
        item.isbn === itemToUpdate.isbn ? itemToUpdate : item
      );
      setShoppingCart(updatedCart);
      calculateTotals(updatedCart); // Recalculate totals after updating
  
      // Save to Firestore or local storage if necessary
      if (currentUser) {
        saveCartToFirestoreAndRTDB(updatedCart);
      } else {
        localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
      }
      setItemToUpdate(null); // Clear the item needing input
    }
  };
  

  
  // Optimistic UI update and debounced cart adding

  // Rendering the optimistic UI for shopping cart

  const renderOptimisticCart = () => {
    return (
      <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {shoppingCart.map((item, index) => (
          <div
            key={index}
            className={`bg-gradient-to-r from-blue-50 to-white shadow-lg rounded-lg p-6 flex flex-col items-center ${
              item.totalEarnings === 0 ? 'border-l-4 border-blue-400' : ''
            }`}
          >
            {/* Dynamic calculation steps */}
            {item.totalEarnings === 0 ? (
              <div className="flex flex-col justify-center items-center h-36 space-y-2">
                <div className="flex items-center space-x-2">
                  <FaSearch className="text-blue-500 animate-pulse" size={24} />
                  <p className="text-blue-500 font-medium text-sm">
                    Scanning ISBN...
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <FaCalculator className="text-yellow-500 animate-spin" size={24} />
                  <p className="text-yellow-500 font-medium text-sm">
                    Calculating offer...
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <FaCheckCircle className="text-green-500 animate-bounce" size={24} />
                  <p className="text-green-500 font-medium text-sm">
                    Almost done...
                  </p>
                </div>
              </div>
            ) : (
              <img
                src={item.imageLink || 'https://via.placeholder.com/150'} // Placeholder image until fetched
                alt={item.itemName}
                className="w-32 h-32 object-cover mb-4 rounded-lg"
              />
            )}
  
            <h3 className="text-lg font-semibold mb-2">
              {item.itemName || 'Fetching...'}
            </h3>
            <p className="text-sm text-gray-500">{item.isbn}</p>
            <p className="text-sm text-gray-700">Qty: {item.quantity}</p>
            <p className="font-semibold text-gray-900 mt-2">
              {item.totalEarnings > 0 ? `$${item.totalEarnings.toFixed(2)}` : 'Calculating...'}
            </p>
          </div>
        ))}
      </div>
    );
  };
  


  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!isValidIsbn(isbn)) {
      setErrorMessage('Please enter a valid 10- or 13-digit ISBN number.');
      return;
    }
  
    try {
      const formattedIsbn = formatIsbn(isbn);
      const response = await axios.post(
        'https://baresellerapp-backend-dra9gpc5ghhuggb0.eastus2-01.azurewebsites.net/add_to_cart',
        { isbn: formattedIsbn, quantity: Number(quantity) },
        { withCredentials: true }
      );
  
      const newCartItem = response.data.shopping_cart[0];
      const updatedCart = mergeCartItems(shoppingCart, [newCartItem]);
  
      setShoppingCart(updatedCart);
      calculateTotals(updatedCart);
  
      if (currentUser) {
        await saveCartToFirestoreAndRTDB(updatedCart);
      } else {
        localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
      }
  
      // Reset input fields
      setIsbn('');
      setQuantity(1);
    } catch (error) {
      console.error('Error adding item manually:', error);
      setErrorMessage('Failed to add item. Please try again.');
    }
  };
  

  const convertIsbn10ToIsbn13 = (isbn10: string): string => {
    const isbnWithoutCheckDigit = '978' + isbn10.substring(0, 9);
    const digits = isbnWithoutCheckDigit.split('').map(Number);
  
    // Calculate the check digit for ISBN-13
    const checkDigit = digits.reduce((sum, digit, index) => {
      return sum + digit * (index % 2 === 0 ? 1 : 3);
    }, 0);
    
    const remainder = checkDigit % 10;
    const isbn13CheckDigit = remainder === 0 ? 0 : 10 - remainder;
  
    return isbnWithoutCheckDigit + isbn13CheckDigit;
  };
  

  const formatIsbn = (isbn: string): string => {
    const formattedIsbn = isbn.replace(/-/g, '');
  
    // Convert ISBN-10 to ISBN-13
    if (formattedIsbn.length === 10) {
      return convertIsbn10ToIsbn13(formattedIsbn);
    }
  
    return formattedIsbn;  // ISBN-13 remains as is
  };
  
// Validate ISBN - it must be either 10 digits (ISBN-10) or exactly 13 digits (ISBN-13)
const isValidIsbn = (isbn: string) => {
  const formattedIsbn = formatIsbn(isbn);
  
  // If ISBN starts with 978 or 979, only valid if it's exactly 13 digits
  if (formattedIsbn.startsWith('978') || formattedIsbn.startsWith('979')) {
    return formattedIsbn.length === 13;  // Ensure all 13 digits are entered
  }
  
  // For other cases, treat it as ISBN-10 and ensure it has exactly 10 digits
  return formattedIsbn.length === 10;
};
  // Handle ISBN input change immediately
  const handleIsbnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsbn(e.target.value);
    setErrorMessage('');  // Reset error message on user input
  };

  const handleCreateShipment = async () => {
    if (!currentUser) {
      setErrorMessage('You have to sign in if you want to create a shipment.');
      navigate('/auth'); // Redirect to the AuthPage
      return;
    }
  
    // Filter out items with totalEarnings === 0
    const updatedCart = shoppingCart.filter(item => item.totalEarnings !== 0);
  
    if (updatedCart.length === 0) {
      setErrorMessage('Your cart is empty after removing items with zero earnings.');
      return;
    }
  
    // Recalculate totals after filtering the cart
    calculateTotals(updatedCart);
  
    if (totalValues < 4.13) {
      setErrorMessage('You need at least $4.13 in total earnings to proceed to checkout.');
      return;
    }

      // Check if any item in the cart has missing dimensions or weight
    const allDimensionsProvided = checkForMissingDimensionsOrWeight(updatedCart);

    // If dimensions are missing, wait for the user to input them before proceeding
    if (!allDimensionsProvided) {
      return; // Exit function until user provides missing values
    }

    
    // Update the state and Firestore/RTDB with the filtered cart
    setShoppingCart(updatedCart);
  
    if (currentUser) {
      await saveCartToFirestoreAndRTDB(updatedCart); // Ensure this completes before proceeding
    } else {
      localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
    }
      // Track shipment creation, only if analytics is enabled
      if (isAnalyticsEnabled()) {
        trackShipmentCreation({
          action: 'Create Shipment',
          label: 'Shipment created with items',
          value: updatedCart.length,
        });
      }
    // Navigate to the ReviewShipment page
    navigate('/review-shipment', {
      state: {
        shoppingCart: updatedCart,
        formData,
        totalValues,
        totalQuantities,
        totalLength,
        totalWidth,
        totalHeight,
        totalWeight
      }
    });
  };

  const calculateEarnings = (initialEarnings: number, quantity: number): number => {
    const diminishingFactor = Math.sqrt(quantity);
    return initialEarnings * diminishingFactor;
  };

  const calculateTotals = (cart: CartItem[]) => {
    const totalValues = cart.reduce((acc, item) => acc + (item.totalEarnings || 0), 0);
    const totalQuantities = cart.reduce((acc, item) => acc + (item.quantity || 0), 0);
    const totalWeight = cart.reduce((acc, item) => acc + (item.weight * item.quantity || 0), 0);

    const totalDimensions = cart.reduce((acc, item) => {
      const length = item.dimensions && item.dimensions.length ? item.dimensions.length : 0;
      const width = item.dimensions && item.dimensions.width ? item.dimensions.width : 0;
      const height = item.dimensions && item.dimensions.height ? item.dimensions.height : 0;

      acc.length = Math.max(acc.length, length);
      acc.width = Math.max(acc.width, width);
      acc.height += height * (item.quantity || 1);

      return acc;
    }, { length: 0, width: 0, height: 0 });

    setTotalValues(totalValues);
    setTotalQuantities(totalQuantities);
    setTotalLength(totalDimensions.length);
    setTotalWidth(totalDimensions.width);
    setTotalHeight(totalDimensions.height);
    setTotalWeight(totalWeight);
  };

  const deleteItem = (isbn: string) => {
    setModalMessage('Are you sure you want to delete this item?');
    setModalAction(() => async () => {  // Use a callback to delay the action
      const updatedCart = shoppingCart.filter(item => formatIsbn(item.isbn) !== formatIsbn(isbn));
      setShoppingCart(updatedCart);
      calculateTotals(updatedCart);
  
      if (currentUser) {
        try {
          const cartRef = doc(db, 'carts', currentUser.uid);
          await setDoc(cartRef, { shopping_cart: updatedCart });
  
          const cartRtdbRef = ref(rtdb, `carts/${currentUser.uid}`);
          await update(cartRtdbRef, { shopping_cart: updatedCart });
        } catch (error) {
          console.error('Error updating cart in Firestore and Realtime Database:', error);
        }
      } else {
        localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
      }
    });
    setIsModalOpen(true);
  };

  const increaseQuantity = async (isbn: string) => {
    const updatedCart = shoppingCart.map((item: CartItem) => {
      if (formatIsbn(item.isbn) === formatIsbn(isbn)) {
        const newQuantity = item.quantity + 1;
        const newTotalEarnings = calculateEarnings(item.initialEarnings, newQuantity);
  
        // const potentialTotalEarnings = shoppingCart.reduce((acc, cartItem) => {
        //   return formatIsbn(cartItem.isbn) === formatIsbn(isbn) ? acc + newTotalEarnings : acc + cartItem.totalEarnings;
        // }, 0);
  
        // if (potentialTotalEarnings > 100) {
        //   setErrorMessage('Increasing the quantity would exceed the $100 earnings limit. Please adjust your cart.');
        //   return item;
        // }
  
        return Object.assign({}, item, {
          quantity: newQuantity,
          totalEarnings: newTotalEarnings,
        });
        
      }
      return item;
    });
  
    setShoppingCart(updatedCart);
    calculateTotals(updatedCart);
  
    if (currentUser) {
      await saveCartToFirestoreAndRTDB(updatedCart); // Ensure this completes before proceeding
    } else {
      localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
    }
  };
  

  const decreaseQuantity = (isbn: string) => {
    const updatedCart = shoppingCart.map((item: CartItem) => {
      if (formatIsbn(item.isbn) === formatIsbn(isbn) && item.quantity > 1) {
        item.quantity -= 1;
        item.totalEarnings = calculateEarnings(item.initialEarnings, item.quantity);
      }
      return item;
    });
    setShoppingCart(updatedCart);
    calculateTotals(updatedCart);

    if (currentUser) {
      saveCart();
    } else {
      localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
    }
  };

  const changeQuantity = (isbn: string, newQuantity: number) => {
    if (newQuantity >= 1) {
      const updatedCart = shoppingCart.map((item: CartItem) => {
        if (formatIsbn(item.isbn) === formatIsbn(isbn)) {
          const newTotalEarnings = calculateEarnings(item.initialEarnings, newQuantity);

          // const potentialTotalEarnings = shoppingCart.reduce((acc, cartItem) => {
          //   return formatIsbn(cartItem.isbn) === formatIsbn(isbn) ? acc + newTotalEarnings : acc + cartItem.totalEarnings;
          // }, 0);

          // if (potentialTotalEarnings > 100) {
          //   setErrorMessage('Changing the quantity would exceed the $100 earnings limit. Please adjust your cart.');
          //   return item;
          // }

          return Object.assign({}, item, {
            quantity: newQuantity,
            totalEarnings: newTotalEarnings,
          });
          
        }
        return item;
      });

      setShoppingCart(updatedCart);
      calculateTotals(updatedCart);

      if (currentUser) {
        saveCart();
      } else {
        localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
      }
    } else {
      setErrorMessage('Quantity must be greater than or equal to 1.');
    }
  };

  const emptyCart = () => {
    setModalMessage('Are you sure you want to empty the cart?');
    setModalAction(() => async () => {  // Use a callback to delay the action
      setShoppingCart([]);
      calculateTotals([]);
  
      if (currentUser) {
        try {
          const cartRef = doc(db, 'carts', currentUser.uid);
          await setDoc(cartRef, { shopping_cart: [] });
  
          const cartRtdbRef = ref(rtdb, `carts/${currentUser.uid}`);
          await update(cartRtdbRef, { shopping_cart: [] });
        } catch (error) {
          console.error('Error clearing cart in Firestore and Realtime Database:', error);
        }
      } else {
        localStorage.removeItem('shoppingCart');
      }
    });
    setIsModalOpen(true);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleSignIn = () => {
    navigate('/auth');
  };

  const handleSignUp = () => {
    navigate('/auth', { state: { isLogin: false } });
  };

  const saveCart = async () => {
    if (currentUser) {
      await saveCartToFirestoreAndRTDB(shoppingCart);
    } else {
      localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart));
    }
  };



  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-r from-green-50 to-green-100 text-gray-800">
<div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-[#05253b] via-[#082632] to-[#063041] relative overflow-hidden px-8 py-12 text-gray-800">
  {/* Animated Gradient Background */}

  {/* Pattern Overlay for Texture */}
  <div className="absolute inset-0 bg-[url('https://www.transparenttextures.com/patterns/cubes.png')] opacity-20 z-0"></div>

  {/* Glowing Effects for Depth
  <div className="absolute top-1/3 left-0 h-96 w-96 bg-[#b3dff5] opacity-50 rounded-full filter blur-3xl animate-pulse z-0"></div>
  <div className="absolute bottom-1/4 right-0 h-96 w-96 bg-[#7aa7c7] opacity-50 rounded-full filter blur-3xl animate-pulse z-0"></div> */}

 <header className="w-full bg-[#b3dff5] shadow-md p-4 fixed top-0 left-0 flex justify-between items-center z-50 border-b border-gray-200">
<div className="flex items-center justify-center flex-1">
      <Link to="/" className="text-3xl font-bold text-gray-900 flex items-center space-x-3 hover:text-blue-700 transition duration-300" aria-label="BooksAreReborn Home">
        <img alt="BooksAreRebornLogo" src={image4} className="h-12 w-auto animate-bounce" />
        <span className="tracking-wider">BooksAreReborn</span>
      </Link>
            <nav className="hidden lg:flex space-x-4 mx-auto">
              <Link to="/" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
                Home
              </Link>
              <Link to="/your-account" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
                Account
              </Link>

            </nav>
          </div>
          <div className="flex items-center space-x-4">
            {currentUser ? (
              <button onClick={handleLogout} className="hidden lg:block bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full shadow transition">
                Log out
              </button>
            ) : (
              <>
                <button onClick={handleSignIn} className="hidden lg:block bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-full shadow transition">
                  Sign In
                </button>
                <button onClick={handleSignUp} className="hidden lg:block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full shadow transition">
                  Sign Up
                </button>
              </>
            )}
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="lg:hidden p-2 rounded-full bg-gray-900 text-white"
            >
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
        </header>

        <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#57b0dc] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 shadow-xl rounded-l-2xl">
          <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5 flex items-center space-x-2">
                <img
                  alt="BooksAreRebornLogo"
                  src={image4}
                  className="h-12 w-auto" // Adjusted logo size for the mobile menu
                />
              </Link>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <Link
                    to="/"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Home
                  </Link>
                  <Link
                    to="/your-account"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Account
                  </Link>
                </div>
                <div className="py-6">
                  {currentUser ? (
                    <button onClick={handleLogout} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                      Log out
                    </button>
                  ) : (
                    <>
                      <button onClick={handleSignIn} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Sign In
                      </button>
                      <button onClick={handleSignUp} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Sign Up
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>

        <main className="pt-24 w-full max-w-4xl px-4">
        <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={async () => {
          if (modalAction) {
            await modalAction();  // Execute the action only after confirm
          }
          setIsModalOpen(false);  // Close the modal
        }}
        message={modalMessage}
      />
      {isPromptOpen && (
        <div style={{ display: 'none' }}>
          <MissingDimensionsPrompt />
        </div>
      )}
        <section className="mb-8 p-4 md:p-8 rounded-xl shadow-2xl bg-white bg-opacity-80 backdrop-blur-lg">
  <motion.h1
    initial={{ opacity: 0, y: -50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="text-3xl md:text-5xl font-bold text-gray-100 mb-4 md:mb-6 p-4 md:p-6 rounded-lg shadow-lg bg-gradient-to-r from-[#240231] to-[#080231] text-center tracking-wide"
  >
    TURN YOUR <span style={{ color: '#32CD32', textShadow: '0 0 10px rgba(50, 205, 50, 0.7)' }}>BOOKS</span> INTO <br /> 
    <span style={{ color: '#FFD700', textShadow: '0 0 15px rgba(255, 215, 0, 1)' }}>CASH</span> <br />
    DON'T LET THEM GO TO<span style={{ color: '#FF4500', textShadow: '0 0 10px rgba(255, 69, 0, 0.8)' }}><br />WASTE</span>!
  </motion.h1>
  
  {errorMessage && (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.3 }}
      className="flex items-start sm:items-center justify-between max-w-md mx-auto mb-4 p-3 md:p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg shadow-md"
      role="alert"
    >
      <div className="flex items-start space-x-2">
        <svg
          className="w-5 h-5 md:w-6 md:h-6 text-red-700"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M18.364 5.636a9 9 0 11-12.728 0M12 9v4m0 4h.01"
          ></path>
        </svg>
        <span className="text-sm md:text-base font-semibold">{errorMessage}</span>
      </div>
      <button
        onClick={() => setErrorMessage('')}
        className="text-red-500 hover:text-red-800 transition-colors duration-200"
      >
        <XMarkIcon className="w-4 h-4 md:w-5 md:h-5" />
      </button>
    </motion.div>
  )}

  <form method="post" onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
    <input
      type="text"
      id="isbn"
      name="isbn"
      placeholder="Enter the ISBN value"
      required
      value={isbn}
      onChange={handleIsbnChange}
      className="w-full p-3 md:p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 transition-all duration-200 text-sm md:text-base"
    />
    {isbn && !isValidIsbn(isbn) && (
      <p className="text-xs md:text-sm text-red-500">
        Please enter a valid 10- or 13-digit ISBN number.
      </p>
    )}

    <input
      type="number"
      id="quantity"
      name="quantity"
      placeholder="Enter the quantity"
      min="1"
      value={quantity}
      onChange={handleQuantityChange}
      className="w-full p-3 md:p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 transition-all duration-200 text-sm md:text-base"
    />

    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      type="submit"
      className={`w-full py-3 md:py-4 bg-gradient-to-r from-[#28a745] to-[#28d47f] text-white font-semibold rounded-full shadow-lg hover:shadow-2xl transition-transform duration-300 ease-in-out ${
        (!isValidIsbn(isbn) || totalValues < 4.13 || loading) ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      disabled={!isValidIsbn(isbn) || totalValues < 4.13 || loading}
    >
      Get Quote!
    </motion.button>
  </form>
</section>



<section className="mb-8 bg-gray-50 p-6 md:p-10 rounded-2xl shadow-2xl">
<h2 className="text-3xl md:text-5xl font-extrabold mb-8 text-gray-900 text-center tracking-wide">
  🛒 Your Shopping Cart
</h2>

  {loading ? (
    renderOptimisticCart()
  ) : (
    <div className="relative">
      {shoppingCart.length > 0 ? (
        <>
          <div className="space-y-6">
            {shoppingCart.map((item, index) => (
              <div
                key={index}
                className={`flex flex-col md:flex-row items-center gap-6 bg-white border shadow-lg rounded-xl p-6 ${
                  item.totalEarnings === 0 ? 'border-red-300 bg-red-50 text-red-600' : ''
                }`}
              >
                <img
                  src={item.imageLink}
                  className="w-32 h-32 md:w-40 md:h-40 rounded-lg shadow-md object-cover"
                  alt={item.itemName}
                />
                <div className="flex-1 space-y-2 text-center md:text-left">
                  <p className="font-semibold text-lg md:text-xl">{item.itemName}</p>
                  <p className="text-sm text-gray-600">ISBN: {item.isbn}</p>
                  <div className="flex items-center justify-center md:justify-start gap-2 mt-2">
                    <button
                      onClick={() => decreaseQuantity(item.isbn)}
                      className="p-2 bg-red-500 text-white rounded-full shadow hover:bg-red-600 focus:ring-2 focus:ring-red-400"
                    >
                      <svg
                        className="w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 2"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1 1h16"
                        />
                      </svg>
                    </button>
                    <input
                      type="number"
                      id={`quantity${item.isbn}`}
                      className="w-14 text-center p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                      value={item.quantity}
                      onChange={(e) => changeQuantity(item.isbn, parseInt(e.target.value))}
                    />
                    <button
                      onClick={() => increaseQuantity(item.isbn)}
                      className="p-2 bg-green-500 text-white rounded-full shadow hover:bg-green-600 focus:ring-2 focus:ring-green-400"
                    >
                      <svg
                        className="w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 18"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 1v16M1 9h16"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="text-center md:text-right">
                  <p className="font-bold text-lg text-gray-900">
                    ${(item.totalEarnings || 0).toFixed(2)}
                  </p>
                  <button
                    className="mt-2 text-red-500 font-semibold hover:underline"
                    onClick={() => deleteItem(item.isbn)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-10 bg-gradient-to-br from-gray-50 via-gray-100 to-gray-200 p-6 rounded-2xl shadow-lg text-center space-y-4">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800">Cart Summary</h3>
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 text-gray-700 font-medium">
              <div className="flex flex-col items-center">
                <span className="text-sm">Total Length</span>
                <span className="text-lg font-semibold">{totalLength.toFixed(2)} in</span>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-sm">Total Width</span>
                <span className="text-lg font-semibold">{totalWidth.toFixed(2)} in</span>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-sm">Total Height</span>
                <span className="text-lg font-semibold">{totalHeight.toFixed(2)} in</span>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-sm">Total Items</span>
                <span className="text-lg font-semibold">{totalQuantities}</span>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-sm">Total Weight</span>
                <span className="text-lg font-semibold">{totalWeight.toFixed(2)} lbs</span>
              </div>
              <div className="flex flex-col items-center text-green-600">
                <span className="text-sm">Total Earnings</span>
                <span className="text-lg font-bold">${(totalValues || 0).toFixed(2)}</span>
              </div>
            </div>
          </div>

        </>
      ) : (
        <p className="text-center text-gray-600 mt-6">Your cart is empty. Please add some items.</p>
      )}
    </div>
  )}

  {shoppingCart.some((item) => item.totalEarnings === 0) && (
    <div className="text-red-600 font-bold text-center mt-6">
      Some items are not accepted for offers at this time.
    </div>
  )}

{shoppingCart.length > 0 && (
  <div className="mt-8 flex flex-col md:relative">
    {totalValues < 4.13 && (
      <div className="text-red-600 font-bold text-center mb-4">
        You need at least $4.13 to proceed to checkout. Your total is: ${(totalValues || 0).toFixed(2)}
      </div>
    )}
    {/* Buttons for mobile */}
    <div className="flex flex-col gap-4 w-full md:hidden">
      <button
        onClick={emptyCart}
        className="bg-red-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-red-600 transition duration-300 transform hover:scale-105 w-full"
      >
        Empty Cart
      </button>
      {totalValues >= 4.13 && (
        <button
          onClick={handleCreateShipment}
          className="bg-blue-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-blue-600 transition duration-300 transform hover:scale-105 w-full"
        >
          Create Shipment
        </button>
      )}
    </div>

    {/* Buttons for desktop */}
    <div className="hidden md:flex md:items-center md:justify-between w-full">
      <button
        onClick={emptyCart}
        className="bg-red-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-red-600 transition duration-300 transform hover:scale-105 w-auto"
      >
        Empty Cart
      </button>
      {totalValues >= 4.13 && (
        <button
          onClick={handleCreateShipment}
          className="bg-blue-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-blue-600 transition duration-300 transform hover:scale-105 w-auto absolute bottom-0 right-0"
          style={{ margin: '1rem' }}
        >
          Create Shipment
        </button>
      )}
    </div>
  </div>
)}


</section>



</main>
      </div>
      <footer className="bg-gradient-to-t from-[#2a3d4f] via-[#1e2a38] to-[#16202c] text-gray-300 py-10 border-t border-gray-600">
      <div className="container mx-auto px-6">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
            <div className="flex items-center space-x-4 flex-shrink-0">
              <img
                alt="BooksAreRebornLogo"
                src={image4}
                className="h-14 w-auto"
              />
              <span className="text-xl font-bold tracking-wide">BooksAreReborn</span>
            </div>
            <div className="flex flex-wrap justify-center space-x-4 md:space-x-8 text-center md:text-left">
              <Link
                to="/privacy-policy"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-of-use"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                Terms of Use
              </Link>
              <Link
                to="/acceptable-use"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                Acceptable Use
              </Link>
              <Link
                to="/contact-us"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                Contact Us
              </Link>
              <Link
                to="/buyback-guide"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                Buyback Guide
              </Link>
              <Link
                to="/faq"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                FAQs
              </Link>
              <a
          href="https://www.affiliatly.com/af-1070204/affiliate.panel?mode=register"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Affiliate Program
        </a>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-8 pt-4">
            <div className="text-center text-gray-500 text-xs">
              &copy; {new Date().getFullYear()} BooksAreReborn. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Getoffer;