import React, { useEffect } from 'react';
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { motion, AnimatePresence } from 'framer-motion';

interface AlertProps {
  title?: string;
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
  show: boolean;
  onClose: () => void;
  autoDismiss?: boolean;
  duration?: number; // milliseconds
  actionLabel?: string;
  onAction?: () => void;
}

const Alert: React.FC<AlertProps> = ({
  title,
  message,
  type,
  show,
  onClose,
  autoDismiss = false,
  duration = 5000,
  actionLabel,
  onAction,
}) => {
  useEffect(() => {
    if (show && autoDismiss) {
      const timer = setTimeout(onClose, duration);
      return () => clearTimeout(timer);
    }
  }, [show, autoDismiss, duration, onClose]);

  const icons = {
    success: <CheckCircleIcon className="h-6 w-6 text-green-500" />,
    error: <XCircleIcon className="h-6 w-6 text-red-500" />,
    warning: <ExclamationCircleIcon className="h-6 w-6 text-yellow-500" />,
    info: <InformationCircleIcon className="h-6 w-6 text-blue-500" />,
  };

  const colors = {
    success: 'bg-green-50 border-green-400 text-green-800',
    error: 'bg-red-50 border-red-400 text-red-800',
    warning: 'bg-yellow-50 border-yellow-400 text-yellow-800',
    info: 'bg-blue-50 border-blue-400 text-blue-800',
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          role="alert"
          aria-live="assertive"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.3 }}
          className={`flex items-start max-w-md w-full mx-auto mt-4 p-4 rounded-lg border ${colors[type]} shadow-md hover:shadow-lg transition-shadow`}
        >
          <div className="flex-shrink-0 mt-1">{icons[type]}</div>
          <div className="ml-3 flex-1 overflow-hidden">
            {title && <h3 className="text-sm font-semibold">{title}</h3>}
            <p className="text-sm mt-0.5 break-words">{message}</p>
            {actionLabel && onAction && (
              <button
                onClick={onAction}
                className="mt-2 text-sm font-medium text-blue-600 hover:underline"
              >
                {actionLabel}
              </button>
            )}
          </div>
          <button onClick={onClose} className="ml-4 mt-1 text-gray-400 hover:text-gray-600">
            <XCircleIcon className="h-5 w-5" />
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Alert;
